import React from "react";
import arrowRight from "../slideImages/diagrams/arrow-right.svg";

export const PointDown = () => {
  return (
    <img
      alt="arrow"
      src={arrowRight}
      style={{
        transform: "rotate(90deg)",
        height: "40px",
        marginTop: "10px",
        marginBottom: "15px"
      }}
    />
  );
};
