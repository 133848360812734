import React from "react";
import { InlineCode } from "../../examples/Code";
import { Tag } from "../../examples/html/syntax/Tag";
import { InfoSlide } from "../../slideComponents/InfoSlide";
import { NarrowCentered, Section } from "../../slideComponents/Section";
import { useSetSlideLocation } from "../../useSlideLocation";
import { PointDown } from "../../slideComponents/PointDown";
import wikipediaH1 from "../../slideImages/elementsInTheWild/wikipedia-bernie-h1.png";
import wikipediaH2H3 from "../../slideImages/elementsInTheWild/bernie-early-career-h2-h3.png";
import { Screenshot } from "../../slideComponents/Screenshot";

const slideId = "h123-element-example";

const H123Example = () => {
  useSetSlideLocation(slideId);
  return (
    <InfoSlide>
      <Section>
        <h3>Headings - h1, h2, h3 etc.</h3>
      </Section>
      <NarrowCentered style={{ textAlign: "center" }}>
        <p style={{ textAlign: "center" }}>
          <InlineCode>
            <Tag _="h1">Important title</Tag>
          </InlineCode>
        </p>
        <PointDown />
        <h1>Important title</h1>
      </NarrowCentered>
      <Section>
        <h4>When do I use it?</h4>
        <p>When you want to display a heading.</p>
        <p>
          The numbers in h1 - h6 determine the importance of the heading, with
          h1 being the largest, most important heading and h6 the smallest,
          least important heading.
        </p>
        <p>
          h1 tags should only be used once on each page. Robots like google's
          use 'h1's to work out what the page is about.
        </p>
        <p>
          Use h2 for a few high level sections, h3 for smaller sub-sections of a
          page, and h4-6 are there in case you need even smaller sub-sections.
        </p>
      </Section>
      <Section>
        <h4>In the wild</h4>
        <p>
          h1 is usually used for the main title, as it is on every wikipedia
          page. This wikipedia page title of 'Bernie Sanders' is in an h1:
        </p>
      </Section>
      <NarrowCentered style={{ textAlign: "center", marginTop: 10 }}>
        <p>
          <InlineCode>
            <Tag _="h1">Bernie Sanders</Tag>
          </InlineCode>
        </p>
        <PointDown />
        <Screenshot
          alt="Bernie Sanders Wikipedia title"
          src={wikipediaH1}
          style={{
            width: "100%"
          }}
        />
      </NarrowCentered>
      <Section>
        <p>
          h2 is used for the section 'Early career', and the subsection
          'Political activism' uses an h3:
        </p>
      </Section>
      <NarrowCentered style={{ textAlign: "center", marginTop: 10 }}>
        <p>
          <InlineCode>
            <Tag _="h2">Early career</Tag>
          </InlineCode>
        </p>
        <p>
          <InlineCode>
            <Tag _="h3">Political activism</Tag>
          </InlineCode>
        </p>
        <PointDown />
        <Screenshot
          alt="Bernie Sanders Early career wikipedia section"
          src={wikipediaH2H3}
          style={{
            width: "100%"
          }}
        />
      </NarrowCentered>
    </InfoSlide>
  );
};

H123Example.slideId = slideId;

export { H123Example };
