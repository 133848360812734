import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Auth } from "./Auth";
import { Home } from "./Home";
import { authClient, dbClient } from "../firebase/authClient";
import { NotFound } from "./NotFound";
import { Learn } from "./learn/Learn";
import { Provider, useDispatch } from "react-redux";
import { store } from "../data/store";
import { updateUser, useUserSelector } from "../data/userSlice";
import { updateUserState } from "../data/userStateSlice";

const App = () => {
  const dispatch = useDispatch();
  const user = useUserSelector();
  useEffect(() => {
    return authClient.listen(user => dispatch(updateUser(user)));
  }, [dispatch]);
  useEffect(() => {
    if (user.id)
      return dbClient.addUserStateListener(user.id, userState =>
        dispatch(updateUserState(userState))
      );
  }, [user.id, dispatch]);
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route path="/auth">
          <Auth />
        </Route>
        <Route path="/learn">
          <Learn />
        </Route>
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </Router>
  );
};

const AppWithProviders = () => (
  <Provider store={store}>
    <App />
  </Provider>
);

export { AppWithProviders as App };
