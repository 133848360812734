import React from "react";
import downArrow from "../slideImages/diagrams/down-arrow.svg";

export const PointFromTop = ({ children, text }) => {
  return (
    <>
      <div
        style={{
          marginBottom: "-15px",
          display: "flex"
        }}
      >
        <div>{text}</div>
        <img
          alt="arrow"
          src={downArrow}
          style={{
            height: "60px"
          }}
        />
      </div>
      <p>{children}</p>
    </>
  );
};
