import React from "react";
import leftArrow from "../slideImages/diagrams/left-arrow.svg";

export const PointFromRight = ({ children, text }) => {
  return (
    <>
      <div style={{ display: "flex", alignItems: "center" }}>
        {children}
        <div style={{ flexGrow: 1 }}>
          <div style={{ textAlign: "right" }}>{text}</div>
          <img
            alt="arrow"
            src={leftArrow}
            style={{
              height: "35px",
              marginRight: "auto"
            }}
          />
        </div>
      </div>
    </>
  );
};
