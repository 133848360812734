import firebase from "firebase/app";
import { FbAuthClient } from "./FbAuthClient";
import { FirestoreDbClient } from "./FirestoreDbClient";

let apiClients;

if (window.apiClients) {
  apiClients = window.apiClients;
} else {
  require("./init");
  apiClients = {
    authClient: new FbAuthClient(firebase),
    dbClient: new FirestoreDbClient(firebase)
  };
}

const { authClient, dbClient } = apiClients;

export { authClient, dbClient };
