import React from "react";
import styled, { css } from "styled-components";
import { colours } from "../../../styles/colours";
import { useSlideLocation } from "../useSlideLocation";

const Root = styled.div`
  padding: 32px 12px 200px 12px;
  @media only screen and (max-width: 600px) {
    padding: 16px 6px 100px 6px;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${colours.grey[1]};
  flex-grow: 1;
`;

const Page = styled.div`
  padding: 18px 54px 36px 54px;
  display: flex;
  flex-direction: column;
  background-color: white;
  max-width: 700px;
  width: 100%;
  min-height: 80vh;
  box-sizing: border-box;
  box-shadow: 0 0.1rem 0.2rem ${colours.grey[5]},
    0 0.2rem 0.2rem ${colours.grey[3]};
  border-radius: 24px;
  @media only screen and (max-width: 600px) {
    padding: 9px 18px 36px 18px;
    border-radius: 12px;
  }
`;

const Content = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Footer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 36px;
  align-items: flex-start;
`;

const buttonStyle = css`
  font-size: 24px;
  padding: 12px 32px;
  border-radius: 0;
  color: ${colours.grey[8]};;
  border: solid 1px ${colours.grey[8]};;
  background: none;
  cursor: pointer;
  border-radius: 5px 0 0 5px;
  outline: none;
  &:hover, &:active, &:focus {
    background-color: ${colours.grey[1]};
    border-color: ${colours.grey[9]};
    color: ${colours.grey[9]};
  }
  &:active, &:focus {
    background-color: ${colours.grey[2]};
  }
`;

const BackButton = styled.button`
  ${buttonStyle}
  border-right: none;
`;

const NextButton = styled.button`
  ${buttonStyle}
  border-radius: 5px;
  * + & {
    border-radius: 0 5px 5px 0;
  }
`;

const InfoSlide = ({ children }) => {
  const { goBack, goForwards } = useSlideLocation();
  return (
    <Root>
      <Page>
        <Content>
          {children}
        </Content>
        <Footer>
          {goBack && <BackButton onClick={goBack}>Back</BackButton>}
          {goForwards && <NextButton onClick={goForwards}>Next</NextButton>}
          {!goForwards && <NextButton>Finished!</NextButton>}
        </Footer>
      </Page>
    </Root>
  );
};

export { InfoSlide };
