import React from "react";
import { InlineCode } from "../../examples/Code";
import { Tag } from "../../examples/html/syntax/Tag";
import { InfoSlide } from "../../slideComponents/InfoSlide";
import { NarrowCentered, Section } from "../../slideComponents/Section";
import { useSetSlideLocation } from "../../useSlideLocation";
import { PointToCenter } from "../../slideComponents/PointToCenter";
import { PointFromBottom } from "../../slideComponents/PointFromBottom";
import youtubeButtonsCircled from "../../slideImages/elementsInTheWild/youtube-buttons-circled.jpg";
import { Screenshot } from "../../slideComponents/Screenshot";
import { InteractiveButtonExample } from "./interactiveExamples/aInterActiveButtonExample";

const slideId = "button-element-example";

const ButtonExample = () => {
  useSetSlideLocation(slideId);
  return (
    <InfoSlide>
      <NarrowCentered>
        <p>When you see a plain old button like this</p>
        <PointToCenter>
          <button>Click me!</button>
        </PointToCenter>
      </NarrowCentered>
      <NarrowCentered>
        <p>Someone, somewhere, has written this:</p>
        <PointFromBottom
          text={<strong style={{ marginLeft: 30 }}>HTML</strong>}
        >
          <div style={{ textAlign: "center" }}>
            <InlineCode>
              <Tag _="button">Click me!</Tag>
            </InlineCode>
          </div>
        </PointFromBottom>
      </NarrowCentered>
      <Section>
        That's a `button` element. There are several important elements like
        this in HTML which combine to make web pages.
      </Section>
      <Section>
        <h4>When do I use it?</h4>
        Button elements are used when you want to perform an action when an
        element is clicked. They can be used:
        <ul>
          <li>to submit forms and searches</li>
          <li>to open a menu</li>
          <li>to log out of a website</li>
          <li>to go to the next page of a tutorial</li>
          <li style={{ listStyleType: "none" }}>...and many other uses</li>
        </ul>
      </Section>
      <Section>
        <h4>In the wild</h4>
        Buttons can be styled to look like anything you want, every blue circle
        from this screen shot is a different button:
        <Screenshot
          alt="Youtube with buttons circled"
          src={youtubeButtonsCircled}
          style={{
            marginTop: "32px"
          }}
        />
      </Section>
      <Section>
        <h4>Interactive example</h4>
        <p>Change the code here to change the button text:</p>
      </Section>
      <InteractiveButtonExample />
      <Section>
        You can also control what happens when you click the button, but before we
        get to that, let's take a quick look at a few more elements...
      </Section>
    </InfoSlide>
  );
};

ButtonExample.slideId = slideId;

export { ButtonExample };
