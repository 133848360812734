const allFiles = (ctx => {
  let keys = ctx.keys();
  let values = keys.map(ctx);
  return keys.reduce((o, k, i) => {
    o[k] = values[i];
    return o;
  }, {});
})(require.context("./slides", true, /\.js$/));

export const slidesById = Object.values(allFiles).reduce(
  (slidesById, module) => {
    const Slide = Object.values(module)[0];
    if (!Slide.slideId) return slidesById;
    return {
      ...slidesById,
      [Slide.slideId]: Slide
    };
  },
  {}
);
