const dup = obj => JSON.parse(JSON.stringify(obj));

export class FirestoreDbClient {
  constructor(fb) {
    this.db = fb.firestore();
  }

  getUserState = async userId => {
    const snap = await this.db.collection("userStates").doc(userId).get();
    return snap.data();
  };

  setUserState = async (userId, userState) => {
    await this.db
      .collection("userStates")
      .doc(userId)
      .set({ userId, ...dup(userState) }, { merge: true });
  };

  addUserStateListener = (userId, func) => {
    const cancelListener = this.db
      .collection("userStates")
      .doc(userId)
      .onSnapshot(snap => func(snap.data()));
    
    this.listeners = [...(this.listeners || []), cancelListener];
    return cancelListener;
  };
}
