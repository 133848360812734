import styled from "styled-components";
import { colours } from "../../../styles/colours";

export const InlineCode = styled.span`
  background-color: ${colours.grey[2]};
  padding: 8px;
  border-radius: 6px;
  box-shadow: inset 2px 2px 2px  ${colours.grey[3]};
  white-space: nowrap;
  word-wrap: normal;
  word-break: keep-all;
`;
