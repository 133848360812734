// import { HtmlTagSyntax } from "./slides/xHtmlTagSyntax";
// import { HtmlTagSyntaxStart } from "./slides/x3HtmlTagSyntaxStartTag";
import { ButtonExample } from "./slides/2elementExamples/aButtonExample";
import { HtmlExamples } from "./slides/1aHtmlExamples";
import { PExample } from "./slides/2elementExamples/bPExample";
import { H123Example } from "./slides/2elementExamples/cH123Example";

export const orderedSlides = [
  HtmlExamples.slideId,
  ButtonExample.slideId,
  PExample.slideId,
  H123Example.slideId
];
