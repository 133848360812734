import { colours } from "../../../styles/colours";
import styled from "styled-components";

export const Screenshot = styled.img`
  max-width: 100%;
  max-height: 200px;
  box-shadow: 0.1rem 0.1rem 0.3rem ${colours.grey[5]},
    0.2rem 0.2rem 0.4rem ${colours.grey[3]};
  border-radius: 5px;
`;
