import React from "react";
import styled, { css } from "styled-components";
import { colours } from "../../../../../styles/colours";

const createSyntaxEl = ({ color, highlightColor }) => styled.span`
  color: ${color};
  font-size: 1rem;
  vertical-align: middle;
  font-family: "iA-Writer-Regular";
  font-weight: 600;
  ${({ highlight }) =>
    highlight
      ? css`
          color: ${highlightColor};
          background-color: ${colours.primary[2]};
          font-family: "iA-Writer-Bold";
          font-weight: bolder;
        `
      : ""}
`;

const SyntaxChunk = styled.span``;

export const SpecialChar = createSyntaxEl({
  color: colours.grey[7],
  highlightColor: colours.grey[9]
});
export const TagName = createSyntaxEl({
  color: colours.primary[7],
  highlightColor: colours.primary[9]
});
export const ChildrenRoot = createSyntaxEl({
  color: colours.grey[8],
  highlightColor: colours.grey[9]
});

export const StartTag = ({ _, highlight }) => (
  <SyntaxChunk highlight={highlight}>
    <SpecialChar highlight={highlight}>{"<"}</SpecialChar>
    <TagName highlight={highlight}>{_}</TagName>
    <SpecialChar highlight={highlight}>{">"}</SpecialChar>
  </SyntaxChunk>
);

export const SelfClosingTag = ({ _, highlight }) => (
  <SyntaxChunk highlight={highlight}>
    <SpecialChar highlight={highlight}>{"<"}</SpecialChar>
    <TagName highlight={highlight}>{_}</TagName>
    <SpecialChar highlight={highlight}>{" />"}</SpecialChar>
  </SyntaxChunk>
);

export const EndTag = ({ _, highlight }) => (
  <SyntaxChunk highlight={highlight}>
    <SpecialChar highlight={highlight}>{"</"}</SpecialChar>
    <TagName highlight={highlight}>{_}</TagName>
    <SpecialChar highlight={highlight}>{">"}</SpecialChar>
  </SyntaxChunk>
);

export const Children = ({ _, highlight }) => (
  <SyntaxChunk highlight={highlight}>
    <ChildrenRoot highlight={highlight}>{_}</ChildrenRoot>
  </SyntaxChunk>
);

const TagWithChildren = ({ _, children }) => (
  <SyntaxChunk>
    <StartTag _={_} />
    <Children _={children} />
    <EndTag _={_} />
  </SyntaxChunk>
);

export const Tag = props =>
  props.children ? (
    <TagWithChildren {...props} />
  ) : (
    <SelfClosingTag {...props} />
  );
