import React from "react";
import arrowUpRight from "../slideImages/diagrams/arrow-up-right.svg";

export const PointFromBottom = ({ children, text }) => {
  return (
    <>
      {children}
      <div
        style={{
          display: "flex"
        }}
      >
        <div style={{ marginTop: 20 }}>{text}</div>
        <img
          alt="arrow"
          src={arrowUpRight}
          style={{
            height: "50px"
          }}
        />
      </div>
    </>
  );
};
