import React from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { authClient } from "../firebase/authClient";
import { colours } from "../styles/colours";

const Root = styled.form`
  padding: 12px;
`;

const Section = styled.div`
  padding: 12px;
`;

const Error = styled(Section)`
  color: ${colours.negative[5]};
`;

export const AuthForm = () => {
  const {
    register,
    handleSubmit,
    errors,
    setError,
    clearErrors,
    formState: { isSubmitting }
  } = useForm();

  const router = useHistory();
  const onSubmit = async details => {
    try {
      await authClient.signIn(details);
    } catch (e) {
      if (e.code === "auth/user-not-found") {
        try {
          await authClient.signUp(details);
        } catch (e) {
          if (!!e.code) {
            return setError("FORM_ERROR", { type: e.code, message: e.message });
          } else {
            throw e;
          }
        }
      } else if (!!e.code) {
        return setError("FORM_ERROR", { type: e.code, message: e.message });
      } else {
        throw e;
      }
    }
    router.push("/");
    return new Promise(() => {});
  };
  return (
    <Root onSubmit={handleSubmit(onSubmit)}>
      <Section>Log in or Register</Section>
      <Section>
        <input
          name="email"
          ref={register}
          onChange={() => clearErrors("FORM_ERROR")}
        />
      </Section>
      <Section>
        <input
          name="password"
          type="password"
          ref={register}
          onChange={() => clearErrors("FORM_ERROR")}
        />
      </Section>
      {errors.FORM_ERROR && <Error>{errors.FORM_ERROR?.message}</Error>}
      <Section>
        <button type="submit" disabled={isSubmitting}>
          Submit
        </button>
      </Section>
    </Root>
  );
};
