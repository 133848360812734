import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/analytics";
import "firebase/performance";

const envOverride = process.env.REACT_APP_ENV;
const nodeEnv = process.env.NODE_ENV;
const isProductionBuild = nodeEnv === "production";
const useProductionConfig = isProductionBuild && !envOverride;

let firebaseConfig;

if (useProductionConfig) {
  firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID
  };
} else {
  firebaseConfig = {
    apiKey: process.env["REACT_APP_FIREBASE_API_KEY_" + envOverride],
    authDomain: process.env["REACT_APP_FIREBASE_AUTH_DOMAIN_" + envOverride],
    databaseURL: process.env["REACT_APP_FIREBASE_DATABASE_URL_" + envOverride],
    projectId: process.env["REACT_APP_FIREBASE_PROJECT_ID_" + envOverride],
    storageBucket:
      process.env["REACT_APP_FIREBASE_STORAGE_BUCKET_" + envOverride],
    messagingSenderId:
      process.env["REACT_APP_FIREBASE_MESSAGING_SENDER_ID_" + envOverride],
    appId: process.env["REACT_APP_FIREBASE_APP_ID_" + envOverride]
  };
}

firebase.initializeApp(firebaseConfig);

if (useProductionConfig) {
  firebase.analytics();
  firebase.performance();
}
