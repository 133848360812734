import { useUserSelector } from "../../data/userSlice";
import { useUserStateSelector } from "../../data/userStateSlice";
import { orderedSlides } from "./orderedSlides";

const { useEffect, useState } = require("react");
const { dbClient } = require("../../firebase/authClient");

export const useSetSlideLocation = slideId => {
  const user = useUserSelector(), userState = useUserStateSelector();
  const [locationIsSet, setLocationIsSet] = useState(false);

  slideId = slideId || userState.currentLocation;

  useEffect(() => {
    if (slideId && user?.id && !locationIsSet && userState.progress) {
      dbClient.setUserState(user.id, {
        currentLocation: slideId,
        progress: {
          [slideId]: {
            status: "started",
            ...userState.progress[slideId]
          }
        }
      });
      setLocationIsSet(true);
    }
  }, [slideId, user?.id, userState, locationIsSet]);
};

export const useSlideLocation = () => {
  const user = useUserSelector(), userState = useUserStateSelector();
  const currentSlideId = userState.currentLocation;

  const nextSlideId = orderedSlides[orderedSlides.indexOf(currentSlideId) + 1];
  const goForwards = nextSlideId ? () => {
    dbClient.setUserState(user.id, {
      currentLocation: nextSlideId,
      progress: {
        [currentSlideId]: {
          status: "completed"
        }
      }
    });
  } : null;

  const previousSlide = orderedSlides[orderedSlides.indexOf(currentSlideId) - 1];
  const goBack = previousSlide ? () => {
    dbClient.setUserState(user.id, {
      currentLocation: previousSlide
    });
  } : null;

  return {
    goBack,
    goForwards,
    currentSlideId
  };
};
