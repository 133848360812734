import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";

export const userSlice = createSlice({
  name: "user",
  initialState: { isLoaded: false },
  reducers: {
    update(state, action) {
      return {
        ...action.payload,
        isLoaded: true
      };
    }
  }
});

export const useUserSelector = () => useSelector(state => ({
  ...state.user,
  isSignedIn: !!state.user.id
}));

export const { update: updateUser } = userSlice.actions;
