import styled from "styled-components";


export const Section = styled.div`
  width: 100%;
  * + & {
    margin-top: 36px;
  }
`;

export const NarrowCentered = styled(Section)`
  max-width: 400px;
`;

export const NarrowestCentered = styled(Section)`
  width: unset;
`;

export const CenteredP = styled.p`
  text-align: center;
`;

export const RightP = styled.p`
  text-align: right;
`;