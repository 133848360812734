export const colours = {
  grey: {
    1: "hsl(240, 70%, 98%)",
    2: "hsl(240, 70%, 95%)",
    3: "hsl(240, 50%, 90%)",
    4: "hsl(240, 30%, 85%)",
    5: "hsl(240, 20%, 80%)",
    6: "hsl(240, 10%, 70%)",
    7: "hsl(240, 10%, 55%)",
    8: "hsl(240, 10%, 35%)",
    9: "hsl(240, 10%, 25%)"
  },
  primary: {
    1: "hsl(204, 100%, 95%)",
    2: "hsl(191, 100%, 90%)",
    3: "hsl(207, 95%, 50%)",
    5: "hsl(211, 90%, 45%)",
    7: "hsl(207, 80%, 40%)",
    8: "hsl(207, 80%, 45%)",
    9: "hsl(207, 90%, 30%)"
  },
  positive: {
    1: "hsl(120, 100%, 95%)",
    2: "hsl(120, 100%, 90%)",
    3: "hsl(120, 80%, 80%)",
    5: "hsl(120, 90%, 45%)",
    6: "hsl(120, 80%, 40%)",
    7: "hsl(120, 85%, 35%)",
    8: "hsl(120, 90%, 30%)",
    9: "hsl(120, 100%, 25%)",
  },
  negative: {
    1: "hsl(0, 100%, 95%)",
    3: "hsl(0, 80%, 80%)",
    5: "hsl(0, 90%, 45%)",
    7: "hsl(0, 80%, 40%)",
    8: "hsl(0, 70%, 45%)",
    9: "hsl(0, 90%, 30%)"
  },
  white: "hsl(0, 0%, 100%)"
};
