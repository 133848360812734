import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useUserSelector } from "../../data/userSlice";
import { useUserStateSelector } from "../../data/userStateSlice";
import { Layout } from "../Layout";
import { orderedSlides } from "./orderedSlides";
import { slidesById } from "./SlidesById";

export const Learn = () => {
  const router = useHistory();
  const user = useUserSelector(),
    userState = useUserStateSelector();
  useEffect(() => {
    if (user.isLoaded && !user.isSignedIn) {
      router.push("/auth");
    }
  }, [user.isSignedIn, user.isLoaded, router]);

  const currentLocation = userState.currentLocation || orderedSlides[0];
  const Slide = slidesById[currentLocation];

  useEffect(() => {
    window.scrollTo(0,0);
  }, [currentLocation]);

  if (!user.isSignedIn || !userState.isInitialized) return null;

  return (
    <Layout>
      <Slide />
    </Layout>
  );
};
