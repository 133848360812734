import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Layout } from "./Layout";

const Root = styled.div`
  padding: 12px;
`;

export const NotFound = () => {
  return (
    <Layout>
      <Root>
        404 - page not found. <Link to="/">Go Home</Link>
      </Root>
    </Layout>
  );
};
