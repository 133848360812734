import React from "react";
import { useSetSlideLocation } from "../useSlideLocation";
import {
  Children,
  EndTag,
  SpecialChar,
  TagName,
  StartTag
} from "../examples/html/syntax/Tag";
import { InlineCode } from "../examples/Code";
import { InfoSlide } from "../slideComponents/InfoSlide";

const HtmlTagSyntaxStart = () => {
  useSetSlideLocation(HtmlTagSyntaxStart.slideId);

  return (
    <InfoSlide>
      <p>The start tag has three parts.</p>
      <p>
        <InlineCode>
          <StartTag _="button" highlight />
          <Children _="Click me!" />
          <EndTag _="button" />
        </InlineCode>
      </p>
      <p>The opening bracket</p>
      <p>
        <InlineCode>
          <SpecialChar highlight>{"<"}</SpecialChar>
          <TagName>button</TagName>
          <SpecialChar>{">"}</SpecialChar>
        </InlineCode>
      </p>
      <p>The tag name</p>
      <p>
        <InlineCode>
          <SpecialChar>{"<"}</SpecialChar>
          <TagName highlight>button</TagName>
          <SpecialChar>{">"}</SpecialChar>
        </InlineCode>
      </p>
      <p>and a closing bracket</p>
      <p>
        <InlineCode>
          <SpecialChar>{"<"}</SpecialChar>
          <TagName>button</TagName>
          <SpecialChar highlight>{">"}</SpecialChar>
        </InlineCode>
      </p>
    </InfoSlide>
  );
};

HtmlTagSyntaxStart.slideId = "html-tag-syntax-start";

export { HtmlTagSyntaxStart };
