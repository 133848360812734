import React from "react";
import { InfoSlide } from "../slideComponents/InfoSlide";
import { NarrowCentered, Section } from "../slideComponents/Section";
import { useSetSlideLocation } from "../useSlideLocation";
import googleScreenshot from "../slideImages/htmlExamples/google.jpg";
import waterOrgScreenshot from "../slideImages/htmlExamples/water-org.png";
import wikipediaScreenshot from "../slideImages/htmlExamples/wikipedia.jpg";
import { Screenshot } from "../slideComponents/Screenshot";
import { PointFromTop } from "../slideComponents/PointFromTop";
import { PointFromBottom } from "../slideComponents/PointFromBottom";
import { PointFromRight } from "../slideComponents/PointFromRight";

const slideId = "html-examples";

const HtmlExamples = () => {
  useSetSlideLocation(slideId);
  return (
    <InfoSlide>
      <Section>
        <h3>Every website is built with HTML</h3>
      </Section>
      <NarrowCentered>
        <PointFromTop text="Built with HTML">
          <Screenshot alt="Google website screenshot" src={googleScreenshot} />
        </PointFromTop>
      </NarrowCentered>
      <NarrowCentered style={{ textAlign: "right" }}>
        <PointFromBottom text="Built with HTML">
          <Screenshot
            alt="Water.org website screenshot"
            src={waterOrgScreenshot}
          />
        </PointFromBottom>
      </NarrowCentered>
      <NarrowCentered>
        <PointFromRight text="Also built with HTML">
          <Screenshot
            alt="Wikipedia website screenshot"
            src={wikipediaScreenshot}
            style={{ maxWidth: "70%" }}
          />
        </PointFromRight>
      </NarrowCentered>
      <Section>
        So, to get building your own website as quickly as possible we'll start
        with a quick introduction to HTML...
      </Section>
    </InfoSlide>
  );
};

HtmlExamples.slideId = slideId;

export { HtmlExamples };
