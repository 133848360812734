const mapUser = ({ uid, email }) => ({ id: uid, email });

export class FbAuthClient {
  constructor(fb) {
    this.auth = fb.auth();
  }

  get user() {
    return mapUser(this.auth.currentUser);
  }

  signUp = async ({ email, password }) => {
    const { user } = await this.auth.createUserWithEmailAndPassword(
      email,
      password
    );
    return mapUser(user);
  };

  signIn = ({ email, password }) =>
    this.auth.signInWithEmailAndPassword(email, password);

  signOut = () => this.auth.signOut();

  listen = setUser => {
    const cancel = this.auth.onAuthStateChanged(user => setUser(user ? mapUser(user) : null));

    this.listeners = [...(this.listeners || []), cancel];
    return cancel;
  }
}
