import React from "react";
import arrowRight from "../slideImages/diagrams/arrow-right.svg";

export const PointToCenter = ({ children }) => {
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <img
          alt="arrow"
          src={arrowRight}
          style={{
            height: "50px",
            marginRight: "-10px"
          }}
        />
        {children}
        <img
          alt="arrow"
          src={arrowRight}
          style={{
            transform: "rotate(180deg)",
            height: "50px",
            marginLeft: "-10px"
          }}
        />
      </div>
    </>
  );
};
