import React from "react";
import { InlineCode } from "../../examples/Code";
import { Tag } from "../../examples/html/syntax/Tag";
import { InfoSlide } from "../../slideComponents/InfoSlide";
import { NarrowCentered } from "../../slideComponents/Section";
import { useSetSlideLocation } from "../../useSlideLocation";
import { PointToCenter } from "../../slideComponents/PointToCenter";

const slideId = "input-element-example";

const ElementExamples = () => {
  useSetSlideLocation(slideId);
  return (
    <InfoSlide>
      <NarrowCentered>
      <div style={{ textAlign: "center" }}>
        <InlineCode>
          <Tag _="input" />
        </InlineCode>
      </div>
    </NarrowCentered>
    <NarrowCentered>
      <PointToCenter>
        <input />
      </PointToCenter>
    </NarrowCentered>
    </InfoSlide>
  );
};

ElementExamples.slideId = slideId;

export { ElementExamples };
