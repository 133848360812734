import React from "react";
import { useSetSlideLocation } from "../useSlideLocation";
import { Children, EndTag, StartTag } from "../examples/html/syntax/Tag";
import { InlineCode } from "../examples/Code";
import { InfoSlide } from "../slideComponents/InfoSlide";
import { CenteredP, RightP, NarrowestCentered, Section } from "../slideComponents/Section";

const HtmlTagSyntax = () => {
  useSetSlideLocation(HtmlTagSyntax.slideId);

  return (
    <InfoSlide>
      <Section>
        <p>
          So what's happening in this HTML code? To make this button
        </p>
        <CenteredP>
          <button>Click me!</button>
        </CenteredP>
        <CenteredP>
          you need:
        </CenteredP>
      </Section>
      <NarrowestCentered>
        <p>A start tag</p>
        <CenteredP>
          <InlineCode>
            <StartTag _="button" highlight />
            <Children _="Click me!" />
            <EndTag _="button" />
          </InlineCode>
        </CenteredP>
      </NarrowestCentered>
      <NarrowestCentered>
        <CenteredP>The button text in the middle</CenteredP>
        <CenteredP>
          <InlineCode>
            <StartTag _="button" />
            <Children _="Click me!" highlight />
            <EndTag _="button" />
          </InlineCode>
        </CenteredP>
      </NarrowestCentered>
      <NarrowestCentered>
        <RightP>And an end tag</RightP>
        <CenteredP>
          <InlineCode>
            <StartTag _="button" />
            <Children _="Click me!" />
            <EndTag _="button" highlight />
          </InlineCode>
        </CenteredP>
      </NarrowestCentered>
    </InfoSlide>
  );
};

HtmlTagSyntax.slideId = "html-tag-syntax";

export { HtmlTagSyntax };
