import React, { useState } from "react";
import styled from "styled-components";
import { InlineCode } from "../../../examples/Code";
import { Tag } from "../../../examples/html/syntax/Tag";
import { PointDown } from "../../../slideComponents/PointDown";
import { NarrowCentered } from "../../../slideComponents/Section";

const InCodeInput = styled.input`
    background-color: hsla(0, 10%, 100%, 0.1);
    border: none;
    font-family: inherit;
    font-size: inherit;
    width: 150px;
    border-radius: 2px;
    box-shadow: inset 0 0 6px 1px  hsl(0, 10%, 100%);
`;

export const InteractiveButtonExample = () => {
  const [buttonText, setButtonText] = useState("- Edit me! -");
  return (
    <NarrowCentered style={{ textAlign: "center" }}>
      <p style={{ textAlign: "center" }}>
        <InlineCode>
          <Tag _="button">
            <InCodeInput
              value={buttonText}
              onChange={e => setButtonText(e.target.value)}
              data-cy="button-text-input"
              onFocus={e => e.target.scrollIntoView({ behavior: "smooth" })}
            />
          </Tag>
        </InlineCode>
      </p>
      <PointDown />
      <div>
        <button>{buttonText || "-"}</button>
      </div>
    </NarrowCentered>
  );
};
