import React from "react";
import { Layout } from "./Layout";
import { AuthForm } from "./AuthForm";

export const Auth = () => {
  return (
    <Layout hideActions={true}>
      <AuthForm/>
    </Layout>
  );
};
