import React from "react";
import { InlineCode } from "../../examples/Code";
import { Tag } from "../../examples/html/syntax/Tag";
import { InfoSlide } from "../../slideComponents/InfoSlide";
import { NarrowCentered, Section } from "../../slideComponents/Section";
import { useSetSlideLocation } from "../../useSlideLocation";
import { PointDown } from "../../slideComponents/PointDown";
import { InteractivePExample } from "./interactiveExamples/bInterActivePExample";

const slideId = "p-element-example";

const PExample = () => {
  useSetSlideLocation(slideId);
  return (
    <InfoSlide>
      <Section>
        <h3>Paragraph / `{"<p>"}` tag</h3>
      </Section>
      <NarrowCentered style={{ textAlign: "center" }}>
        <p style={{ textAlign: "center" }}>
          <InlineCode>
            <Tag _="p">Your paragraph text</Tag>
          </InlineCode>
        </p>
        <PointDown />
        <p>Your paragraph text</p>
      </NarrowCentered>
      <Section>
        <h4>When do I use it?</h4>
        <p>
          This one doesn't need much explanation. `{"<p>"}` tags arrange the
          text you put between them into a paragraph, making sure words go onto
          the next line when they need to, and leaving an appropriate gap before
          the next paragraph tag.
        </p>
      </Section>
      <Section>
        <h4>In the wild</h4>
        <p>
          You'll see this anywhere that has long flowing text. That includes
          news articles, blog posts, Wikipedia etc.
        </p>
      </Section>
      <Section>
        <h4>Interactive example</h4>
        <p>Change the text and add a new paragraph:</p>
      </Section>
      <InteractivePExample />
    </InfoSlide>
  );
};

PExample.slideId = slideId;

export { PExample };
