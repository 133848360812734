import React, { useState } from "react";
import styled from "styled-components";
import { InlineCode } from "../../../examples/Code";
import { Tag } from "../../../examples/html/syntax/Tag";
import { PointDown } from "../../../slideComponents/PointDown";
import { NarrowCentered } from "../../../slideComponents/Section";

const InCodeInput = styled.input`
  background-color: hsla(0, 10%, 100%, 0.1);
  border: none;
  font-family: inherit;
  font-size: inherit;
  width: 250px;
  border-radius: 2px;
  box-shadow: inset 0 0 6px 1px hsl(0, 10%, 100%);
`;

const EditableSection = styled.div`
  margin-top: 20px;
`;

export const InteractivePExample = () => {
  const [paragraphIds, setParagraphIds] = useState([1, 2]);
  const [paragraphs, setParagraphs] = useState({
    1: "The first paragraph text is really long to show how paragraphs work on multiple lines",
    2: "Second <p> content"
  });
  return (
    <NarrowCentered style={{ textAlign: "center" }}>
      <p style={{ textAlign: "center" }}>
        {paragraphIds.map((id, index) => (
          <EditableSection>
            <InlineCode>
              <Tag _="p">
                <InCodeInput
                  value={paragraphs?.[id] || ""}
                  onChange={e =>
                    setParagraphs(paragraphs => ({
                      ...paragraphs,
                      [id]: e.target.value
                    }))
                  }
                  data-cy="paragraph-text-input"
                  onFocus={e => e.target.scrollIntoView({ behavior: "smooth" })}
                />
              </Tag>
            </InlineCode>
          </EditableSection>
        ))}
        <EditableSection>
          <button
            onClick={() =>
              setParagraphIds(ids => [...ids, ids[ids.length - 1] + 1])
            }
          >
            Add paragraph
          </button>
        </EditableSection>
      </p>
      <PointDown />
      <div>
        {paragraphIds.map(id => (
          <p>{paragraphs?.[id] || ""}</p>
        ))}
      </div>
    </NarrowCentered>
  );
};
