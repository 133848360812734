import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useUserSelector } from "../data/userSlice";
import { useUserStateSelector } from "../data/userStateSlice";
import { colours } from "../styles/colours";
import { Layout } from "./Layout";

const Root = styled.div`
  padding: 12px;
  padding-top: 90px;
  text-align: center;
`;

const BigButtonLink = styled(Link)`
  color: ${colours.primary[8]};
  font-size: 2rem;
  padding: 2rem 4rem;
  border-radius: 18px;
  @media only screen and (max-width: 600px) {
    font-size: 1.5rem;
    padding: 1rem 2rem;
    border-radius: 9px;
  }
  @media only screen and (max-width: 350px) {
    font-size: 1rem;
  }
  font-family: "Metropolis-Black";
  text-decoration: none;
  border: solid 2px ${colours.primary[8]};
  &:hover {
    color: ${colours.primary[9]};
    border-color: ${colours.primary[9]};
  }
`;

export const Home = () => {
  const userState = useUserStateSelector();
  const user = useUserSelector();
  if (!user.isLoaded) return null;
  if (user.isSignedIn && !userState.isInitialized) return null;
  return (
    <Layout>
      <Root>
        <BigButtonLink to="/learn">
          {userState.currentLocation ? "Continue learning" : "Start learning"}
        </BigButtonLink>
      </Root>
    </Layout>
  );
};
